import { jsxs, jsx } from 'react/jsx-runtime';
import * as React from 'react';
import * as RHoverCard from '@radix-ui/react-hover-card';
import { classNames } from '../utils/classNames';

function HoverCard({
  trigger,
  children,
  pointerEvents,
  contentProps,
  disabled,
  side,
  portal = true,
  ...rest
}) {
  if (disabled) {
    return trigger;
  }
  const Portal = portal ? RHoverCard.Portal : React.Fragment;
  const portalProps = portal ? { className: "z-50" } : {};
  return /* @__PURE__ */ jsxs(RHoverCard.Root, { closeDelay: 100, openDelay: 0, ...rest, children: [
    /* @__PURE__ */ jsx(RHoverCard.Trigger, { asChild: true, children: trigger }),
    /* @__PURE__ */ jsx(Portal, { ...portalProps, children: /* @__PURE__ */ jsxs(
      RHoverCard.Content,
      {
        side,
        sideOffset: 5,
        ...contentProps,
        className: classNames(
          pointerEvents ? "pointer-events-auto" : "pointer-events-none",
          "bg-gray-200 dark:border dark:border-secondary dark:bg-tertiary shadow-lg w-full max-w-lg p-3 rounded-md dark:text-white hover-card dropdown-fade !z-10",
          contentProps == null ? void 0 : contentProps.className
        ),
        children: [
          children,
          rest.showArrow ?? true ? /* @__PURE__ */ jsx(RHoverCard.Arrow, { className: "fill-current text-white dark:text-tertiary" }) : null
        ]
      }
    ) })
  ] });
}

export { HoverCard };
